import React from 'react';
import { graphql } from 'gatsby';
import { StaticQuery } from "gatsby";
// import { graphql } from 'react-apollo';
// import gql from 'graphql-tag';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ObjectCard from '../components/ObjectCard';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const CARDS_PER_PAGE = 6;

const Lores = () => (
  <StaticQuery
    query={query}
    render={data => (
      <Layout>

        <SEO 
          title="Lore"
          description="Stories about spooky places and people."
        />

        <Typography variant="h2">
          Lore
        </Typography>
        <section>
            <Grid container spacing={2}>
                {data.gcms.lores.map(lore => (
                  <ObjectCard 
                    key={lore.id}
                    title={lore.title}
                    link={`/lore/${lore.slug}`}
                    image={lore.gallery[0]}
                    relatedPlaceCount = {lore.places.length}
                    relatedBookCount = {lore.books.length}
                    relatedInvestigationCount = {lore.investigations.length}
                    relatedTourCompanyCount = {lore.tourCompanies.length}
                    relatedLoreCount = {0}
                    relatedPersonCount = {lore.people.length}
                  />
                ))}
            </Grid>

            
        </section>
      </Layout>
    )}
  />
);

const query = graphql`
query {
  gcms {
    lores(orderBy: publishedAt_DESC) {
      id
      title
      slug
      gallery (first: 1) {
        handle
        title
      }
      books {id}
      investigations {id}
      tourCompanies {id}
      places {id}
      people {id}
    }
    loresConnection {
      aggregate {
        count
      }
    }
  }
}
`;

export default Lores;

// const Lores = ({
//     data: { loading, error, lores, loresConnection, networkStatus },
//     loadMoreCards,
//   }) => {
//     if (error) return <h1>Error fetching lore!</h1>;
//     if (lores && loresConnection) {
//       const areMorePosts = lores.length < loresConnection.aggregate.count;
//       return (
//         <React.Fragment>
//           <Typography variant="h2">
//             Lore
//           </Typography>
//           <section>
//               <Grid container spacing={2}>
//                   {lores.map(lore => (
//                     <ObjectCard 
//                       title={lore.title}
//                       link={`/lore/${lore.slug}`}
//                       image={lore.gallery[0]}
//                       relatedPlaceCount = {lore.places.length}
//                       relatedBookCount = {lore.books.length}
//                       relatedInvestigationCount = {lore.investigations.length}
//                       relatedTourCompanyCount = {lore.tourCompanies.length}
//                       relatedLoreCount = {0}
//                       relatedPersonCount = {lore.people.length}
//                     />
//                   ))}
//               </Grid>

//               <Grid container justify="center" style={{marginTop: '16px'}}>
//                 <Grid item >
//                   {areMorePosts ? (
//                     <Button 
//                       variant="contained" 
//                       color="primary"
//                       disabled={loading}
//                       onClick={() => loadMoreCards()}
//                     >
//                       {loading ? 'Loading...' : 'Show More Lore'}
//                     </Button>
//                   ) : (
//                     ''
//                   )}
//                 </Grid>
//               </Grid>
//           </section>
//         </React.Fragment>
//       );
//     }
//     return <h2>Loading lore...</h2>;
//   };

// export const query = gql`
//   query lores($first: Int!, $skip: Int!) {
//     lores(orderBy: publishedAt_DESC, first: $first, skip: $skip) {
//       id
//       title
//       slug
//       gallery (first: 1) {
//         handle
//         title
//       }
//       books {id}
//       investigations {id}
//       tourCompanies {id}
//       places {id}
//       people {id}
//     }
//     loresConnection {
//       aggregate {
//         count
//       }
//     }
//   }
// `;

// export const queryVars = {
//   skip: 0,
//   first: CARDS_PER_PAGE,
// };
  
// export default graphql(query, {
//   options: {
//     variables: queryVars,
//   },
//   props: ({ data }) => ({
//     data,
//     loadMoreCards: () => {
//       return data.fetchMore({
//         variables: {
//           skip: data.lores.length,
//         },
//         updateQuery: (previousResult, { fetchMoreResult }) => {
//           if (!fetchMoreResult) {
//             return previousResult;
//           }
//           return Object.assign({}, previousResult, {
//             lores: [...previousResult.lores, ...fetchMoreResult.lores],
//           });
//         },
//       });
//     },
//   }),
// })(Lores);